import { ChangeDetectionStrategy, Component, HostBinding, OnInit, signal } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '@fitup-monorepo/core/lib/services/login/login.service';
import { LocalStorageService } from 'ngx-webstorage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { Store } from '@ngrx/store';
import { OAuth2Service } from '@fitup-monorepo/core/lib/services/auth/oauth2.service';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { getHeaderTheme } from '@fitup-monorepo/core/lib/util/style.util';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInPage implements OnInit {
  @HostBinding('class')
  public get theme(): string {
    return environment.preferredThemes.registerPage;
  }

  public readonly form: FormGroup = this.formBuilder.group(
    {
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    },
    { updateOn: 'blur' }
  );

  #loginErrorString: string | undefined;
  protected readonly previousUrl = '/register';
  protected readonly isButtonDisabled = signal(false);
  protected readonly getHeaderTheme = getHeaderTheme();

  constructor(
    private readonly translateService: TranslateService,
    private readonly loginService: LoginService,
    private readonly navController: NavController,
    private readonly localStorage: LocalStorageService,
    private readonly formBuilder: FormBuilder,
    private readonly toastService: ToastService,
    private readonly store: Store,
    private readonly oauth2: OAuth2Service
  ) {}

  public ngOnInit(): void {
    const username = this.localStorage.retrieve('auth_mail');
    if (username) {
      this.form.patchValue({ username });
    }
    this.translateService.get('LOGIN_ERROR').subscribe(value => {
      this.#loginErrorString = value;
    });
  }

  public registerWithOauth2(provider: 'google' | 'apple'): void {
    this.oauth2.startOAuth2(provider);
  }

  public async doLogin(): Promise<void> {
    this.isButtonDisabled.set(true);

    try {
      await this.loginService.login({ ...this.form.value, rememberMe: true });
      await this.handleLoginSuccess();
    } catch (e) {
      console.log('Login failed', e);
      await this.showLoginErrorToast();
    }
  }

  private async handleLoginSuccess(): Promise<void> {
    this.isButtonDisabled.set(false);
    await this.navController.navigateRoot('/tabs');
  }

  private async showLoginErrorToast(): Promise<void> {
    this.isButtonDisabled.set(false);
    this.form.patchValue({ password: '' });
    await this.toastService.showError(this.#loginErrorString);
  }
}
