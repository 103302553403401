import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Group, GroupCreateOrUpdateRequest, GroupOverview } from '../model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class GroupService {
  constructor(private readonly httpClient: HttpClient) {}

  public getGroupOverview(): Observable<GroupOverview> {
    return this.httpClient.get<GroupOverview>(`${ApiService.API_URL}/leagues`);
  }

  public joinGroup(invitationCode: string): Observable<Group> {
    return this.httpClient.put<Group>(`${ApiService.API_URL}/leagues/join/${invitationCode}`, null);
  }

  public leaveGroup(groupId: number): Observable<Group> {
    return this.httpClient.put<Group>(`${ApiService.API_URL}/leagues/${groupId}/leave`, null);
  }

  public createGroup(request: GroupCreateOrUpdateRequest): Observable<Group> {
    return this.httpClient.post<Group>(`${ApiService.API_URL}/leagues`, request);
  }

  public updateGroup(groupId: number, request: GroupCreateOrUpdateRequest): Observable<Group> {
    return this.httpClient.put<Group>(`${ApiService.API_URL}/leagues/${groupId}`, request);
  }

  public deleteGroup(groupId: number): Observable<void> {
    return this.httpClient.delete<void>(`${ApiService.API_URL}/leagues/${groupId}`);
  }
}
